<template>
  <div>
    <dv-full-screen-container>
      <div class="bg">

        <div class="buju">
          <!--左侧-->
          <div style="margin-top: 82px;width: 26%;">
            <div class="tianqi">
              <!--<span style="margin-right: 15px;">多云转晴</span>-->
              <!--<span style="margin-right: 15px;">17-22℃</span>-->
              <!--<span>东南风3级</span>-->
            </div>

            <!--季度目标完成-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">收入总额</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Total revenue </span>
            </div>
            <div style="margin-top: 20px;">
              <!--<dv-border-box-8 :reverse="true">-->
              <!--  -->
              <!--</dv-border-box-8>-->
              <div style="display: flex;justify-content: space-around;margin-top: 20px;">
                <div class="jinebg" v-for="(item,index) in actual_payment" :key="index">
                  <span style="font-size: 43px;font-weight: 700;color: #ffffff;">{{item}}</span>
                </div>
              </div>
              <div style="margin-top: 20px;">
                <Rental @actual_payment="queryactual_payment"/>
              </div>
            </div>
          </div>

          <!--中间-->
          <div style="width: 50%;">
            <div style="text-align: center;">
              <span style="font-size: 48px;font-weight: 400;color: #ffffff;letter-spacing: 7.2px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{title}}财务大屏</span>
            </div>
            <div style="margin-top: 30px;">
              <div class="mubiao1" style="padding-left: 100px;">
                <span style="font-size: 20px;font-weight: 700;color: #ffffff;">支出账户</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;margin-left: 20px;">Expense Account </span>
              </div>
              <div style="border: 1px solid #0bafc3;box-shadow: 0px 0px 24px 0px #02f0ff inset;padding-left: 10px;padding-right: 10px; padding-top: 5px;padding-bottom: 5px;display: flex;align-items: center;justify-content: center;width: 30%;margin: auto;margin-top: 20px;">
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;">总支出金额</span>
                <span style="font-size: 28px;font-weight: 700;color: #ffffff;letter-spacing: 1.4px;margin-left: 10px;">{{sum}}</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #fafafa;margin-left: 10px;">元</span>
              </div>
              <div style="margin-top: 20px;" class="charts-box1">
                <!--<qiun-vue-ucharts-->
                <!--    type="line"-->
                <!--    :opts="opts1"-->
                <!--    :chartData="chartData1"-->
                <!--/>-->
                <qiun-vue-ucharts
                    type="column"
                    :opts="opts1"
                    :chartData="chartData1"
                />
              </div>

              <div class="mubiao1" style="padding-left: 100px;">
                <span style="font-size: 20px;font-weight: 700;color: #ffffff;">收入账户</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;margin-left: 20px;">Income Account </span>
              </div>
              <div style="border: 1px solid #0bafc3;box-shadow: 0px 0px 24px 0px #02f0ff inset;padding-left: 10px;padding-right: 10px; padding-top: 5px;padding-bottom: 5px;display: flex;align-items: center;justify-content: center;width: 54%;margin: auto;margin-top: 20px;">
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;">本期总金额</span>
                <span style="font-size: 28px;font-weight: 700;color: #ffffff;letter-spacing: 1.4px;margin-left: 10px;">{{current_period_earnings}}</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #fafafa;margin-left: 10px;">元</span>
                <!--<span style="font-size: 16px;font-weight: 400;color: #ffffff;margin-left: 20px;">本期总收益</span>-->
                <!--<span style="font-size: 28px;font-weight: 700;color: #ffffff;letter-spacing: 1.4px;color: #FF4A4A;margin-left: 10px;">-{{current_period_money}}</span>-->
                <!--<span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #fafafa;margin-left: 10px;">元</span>-->
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;margin-left: 20px;">本期总收益</span>
                <span style="font-size: 28px;font-weight: 700;color: #ffffff;letter-spacing: 1.4px;margin-left: 10px;">{{current_period_money}}</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #fafafa;margin-left: 10px;">元</span>
              </div>
              <div style="margin-top: 20px;">
                <Income @current_period_earnings="querycurrent_period_earnings" @current_period_money="querycurrent_period_money"/>
              </div>

            </div>
          </div>

          <!--右侧-->
          <div style="margin-top: 30px;">
            <div class="time">
              <!--<span>2023-03-15  09：15：36</span>-->
              <span>{{time}}</span>
            </div>
            <!--服务项目占比-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">项目支出</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Item of expenditure </span>
            </div>
            <div style="margin-top: 20px;">
              <div style="border: 1px solid #0bafc3;box-shadow: 0px 0px 24px 0px #02f0ff inset;padding-left: 10px;padding-right: 10px; padding-top: 5px;padding-bottom: 5px;display: flex;align-items: center;justify-content: center;width: 70%;margin: auto;margin-top: 20px;">
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;">总金额</span>
                <span style="font-size: 28px;font-weight: 700;color: #ffffff;letter-spacing: 1.4px;margin-left: 10px;">{{total_amount}}</span>
                <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #fafafa;margin-left: 10px;">元</span>
              </div>
              <div>
                <ProportionOfServiceItems @total_amount="querytotal_amount"/>
              </div>
            </div>

            <!--每月销售趋势-->
            <div class="mubiao">
              <span style="font-size: 20px;font-weight: 700;color: #ffffff;">收支情况</span>
              <span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #ffffff;">Item of expenditure </span>
            </div>
            <div style="margin-top: 20px;">
              <div style="border: 1px solid #0bafc3;box-shadow: 0px 0px 24px 0px #02f0ff inset;padding-left: 10px;padding-right: 10px; padding-top: 5px;padding-bottom: 5px;display: flex;align-items: center;justify-content: center;width: 70%;margin: auto;margin-top: 20px;">
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;">收入：</span>
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;">{{income}}</span>
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;margin-left: 20px;">支出：</span>
                <span style="font-size: 16px;font-weight: 400;color: #ffffff;">{{expend}}</span>
                <!--<span style="font-size: 28px;font-weight: 700;color: #ffffff;letter-spacing: 1.4px;margin-left: 10px;">39,072</span>-->
                <!--<span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #fafafa;margin-left: 10px;">元</span>-->
              </div>
              <div class="charts-box" v-if="show">
                <qiun-vue-ucharts
                    type="bar"
                    :opts="opts"
                    :chartData="chartData"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
// 引入总额
import Rental from './components/rental.vue'
// 引入支出账户
import Expenditure from './components/expenditure.vue'
//引入收入账户
import Income from './components/income.vue'
//引入服务项目占比
import ProportionOfServiceItems from './components/proportionOfServiceItems.vue'
// 引入的接口
import {replacement} from "@/api/yunli";

import qiunVueUcharts from '@qiun/vue-ucharts'
import {expenditure_statistics, income_detail} from "@/api/finance";


export default {
  components:{
    ProportionOfServiceItems,
    Rental,
    Expenditure,
    Income,
    qiunVueUcharts
  },
  data(){
    return{
      // 公司名称
      title: localStorage.getItem('caiwutitle'),

      actual_payment:'',
      institution_code:'',

      total_amount:'',

      expend:'',
      income:'',

      current_period_earnings:'',
      current_period_money:'',

      sum:'',

      quota:{},

      // 时间
      time: new Date().toLocaleString(),

      // 销售排行
      show:true,
      chartData: {},
      //您可以通过修改 config-ucharts.js 文件中下标为 ['bar'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts: {
        color: ["#6CD9D9"],
        fontColor:'#BCD9FF',
        padding: [15,30,0,5],
        enableScroll: false,
        legend: {},
        xAxis: {
          boundaryGap: "justify",
          disableGrid: false,
          min: 0,
          axisLine: true,
          max: 70,
          gridType:'dash',
          // #
          fontColor:'#BCD9FF',
          titleFontColor:'#BCD9FF'
        },
        yAxis: {
          fontColor:'#BCD9FF'
        },
        extra: {
          bar: {
            type: "stack",
            width: 30,
            meterBorde: 1,
            meterFillColor: "#FFFFFF",
            activeBgColor: "#000000",
            activeBgOpacity: 0.08,
            categoryGap: 2
          }
        }
      },

      chartData1: {},
      //您可以通过修改 config-ucharts.js 文件中下标为 ['line'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts1: {
        fontColor:'#BCD9FF',
        dataLabel: false,
        dataPointShape: false,
        color: ["#1890FF","#91CB74","#FAC858","#EE6666","#73C0DE","#3CA272","#FC8452","#9A60B4","#ea7ccc"],
        padding: [15,15,0,5],
        enableScroll: false,
        legend: {},
        xAxis: {
          disableGrid: true,
          gridType:'dash',
          axisLineColor:'#BCD9FF',
          gridColor:'#104763',
          fontColor:'#BCD9FF',
          dashLength: 20,
          axisLine:false,
          marginTop:10
        },
        yAxis: {
          gridType: "dash",
          dashLength: 4,
          gridColor:'#104763',
          data: [
            {
              min: 0,
              max: 150
            }
          ],
          axisLine:false,
        },
        extra: {
          column: {
            type: "group",
            width: 30,
            activeBgColor: "#000000",
            activeBgOpacity: 0.08
          }
        }
      }

    }
  },

  mounted() {
    // 时间计算
    var self = this
    setInterval(function() {
      self.time = new Date().toLocaleString()
    }, 1000)

    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      this.getCode();
      console.log(localStorage.getItem('institution_code'))
    }else {
      this.getServerData();
      this.getServerData();
      this.getServerData1();
    }

    // this.getCode();
    //
    // this.getServerData();
    // this.getServerData();
    // this.getServerData1();
  },

  methods:{
    //获取销售总额接口
    queryactual_payment(value){
      console.log(value,'总额')
      this.actual_payment = value;
      //转字符串用于页面循环
      this.actual_payment = this.actual_payment.toString();
    },

    //获取项目支出总金额
    querytotal_amount(value){
      this.total_amount = value;
    },

    // 置换机构码
    getCode(){
      replacement().then(res => {
        console.log(res);
        this.title = res.data.company_name;
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        this.getServerData();
        this.getServerData();
        this.getServerData1();
      })
    },


    getServerData() {
      //模拟从服务器获取数据时的延时
      setTimeout(() => {
        income_detail(localStorage.getItem('institution_code')).then(res => {
          this.expend = res.data.expend;
          this.income = res.data.income;
          let data = {
            categories: res.data.y,
            series: [
              {
                name: "项目",
                data: res.data.x
              },
            ]
          };
          this.chartData = JSON.parse(JSON.stringify(data));
        })
        //模拟服务器返回数据，如果数据格式和标准格式不同，需自行按下面的格式拼接

      }, 500);
      this.show = true;
    },



    getServerData1() {
      expenditure_statistics(localStorage.getItem('institution_code')).then(res => {
        console.log('统计统计统计',res)
        this.sum = res.data.sum;
        //模拟从服务器获取数据时的延时
        setTimeout(() => {
          //模拟服务器返回数据，如果数据格式和标准格式不同，需自行按下面的格式拼接
          // let data = {
          //   categories: ["2018","2019","2020","2021","2022","2023"],
          //   series: [
          //     {
          //       name: "成交量A",
          //       linearColor: [
          //         [
          //           0,
          //           "#1890FF"
          //         ],
          //         [
          //           0.25,
          //           "#00B5FF"
          //         ],
          //         [
          //           0.5,
          //           "#00D1ED"
          //         ],
          //         [
          //           0.75,
          //           "#00E6BB"
          //         ],
          //         [
          //           1,
          //           "#90F489"
          //         ]
          //       ],
          //       setShadow: [
          //         3,
          //         8,
          //         10,
          //         "#1890FF"
          //       ],
          //       data: [15,45,15,45,15,45]
          //     },
          //     {
          //       name: "成交量B",
          //       data: [55,85,55,85,55,85]
          //     },
          //     {
          //       name: "成交量C",
          //       linearColor: [
          //         [
          //           0,
          //           "#FAC858"
          //         ],
          //         [
          //           0.33,
          //           "#FFC371"
          //         ],
          //         [
          //           0.66,
          //           "#FFC2B2"
          //         ],
          //         [
          //           1,
          //           "#FA7D8D"
          //         ]
          //       ],
          //       setShadow: [
          //         3,
          //         8,
          //         10,
          //         "#FC8452"
          //       ],
          //       data: [95,125,95,125,95,125]
          //     }
          //   ]
          // };
          let series = [];
          res.data.month.forEach((item1,index1) => {
            let shuju = {
              name: item1
            }
            series.push(shuju)
            res.data.y.forEach((item2,index2) => {

            })
          })

          console.log('series',series)
          let data = {
            categories: res.data.x,
            series: res.data.y
            // series: [
            //   {
            //     name: "10月",
            //     data: [15,45]
            //   },
            //   {
            //     name: "11月",
            //     data: [55,85]
            //   },
            //   {
            //     name: "12月",
            //     data: [95,125]
            //   }
            // ]
          };
          this.chartData1 = JSON.parse(JSON.stringify(data));
        }, 500);
      })
    },


    querycurrent_period_earnings(value){
      this.current_period_earnings = value;
    },

    querycurrent_period_money(value){
      this.current_period_money = value;
    },



  },

}
</script>

<style lang="scss" scoped>
/*清除默认边距*/
*{
  margin: 0;
  padding: 0;
}
/*设置html标签高度为100%，body高度为100%*/
html,body{
  height: 100%;
}
//背景图片
.bg{
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/daping/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.buju{
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

//左侧
.mubiao{
  width: 367px;
  height: 41px;
  background-image: url("../../assets/images/daping/wancheng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 18px;
}
.tianqi{
  font-size: 16px;
  font-weight: 400;
  color: #c9d3db;
}

.jinebg{
  width: 65px;
  height: 83px;
  background-image: url("../../assets/images/daping/qian.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-right: 15px;
}
//左侧结尾

.mubiao1{
  //width: 367px;
  height: 41px;
  background-image: url("../../assets/images/daping/wancheng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  //justify-content: space-evenly;
  align-items: center;
  margin: auto;
  margin-top: 18px;
}


//右侧
.time{
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0px 3px 6px 0px rgba(6,211,255,0.40);
}
//右侧结尾

.charts-box {
  height: 200px;
}

.charts-box1 {
  height: 250px;
}

</style>
