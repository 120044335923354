<!--大屏 支出账户-->
<template>
  <div>
    <div id="income" style="height: 250px;"></div>
  </div>
</template>

<script>
import {replacement} from "@/api/yunli";
import {income_statistics} from "@/api/finance";

export default {
  data(){
    return{
      institution_code:'',
    }
  },

  mounted() {
    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      console.log(123465123)
      this.getCode();
    }else {
      console.log(456456465)
      console.log(localStorage.getItem('institution_code'))
      //获取收入总额接口
      this.getCharts();
    }
  },

  methods:{
    // 置换机构码
    getCode(){
      replacement().then(res => {
        console.log(res);
        //大屏标题
        localStorage.setItem('caiwutitle',res.data.company_name);
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        this.getCharts();
      })
    },

    getCharts() {
      income_statistics(localStorage.getItem('institution_code')).then(res => {
        // 总金额
        this.$emit('current_period_earnings',res.data.current_period_earnings);
        // 总收益
        this.$emit('current_period_money',res.data.current_period_money);
        res.data.zh.forEach(item => {
          item.value = item.price;
          item.name = item.type
        })
        const chartBox = this.$echarts.init(document.getElementById("income"));
        const option = {
          title: {
            // text: "推广佣金\n\r\n1000",
            x: "center",
            y: "center",
            top: "90",
            textStyle: {
              fontSize: 20,
              fontWeight: "700",
              color: "#F5F5F6"
            }
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            show: true,
            top: "center",
            right: "20%",
            orient: "vertical",
            itemGap: 30,
            icon: "circle",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              fontSize: 16,
              fontFamily: "Source Han Sans CN",
              fontWeight: "normal",
              color: "#FFFFFF",
              opacity: 0.8,
              padding: [0, 0, 0, 6],
            },
          },
          grid: {
            top:'3%',
            left: '3%',
            right: '4%',
            bottom: '0%',
            containLabel: true
          },
          series: [
            {
              name: '统计',
              type: 'pie',
              radius: ['100%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 0
              },
              label: {
                show: false,
                position: 'center'
              },
              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: 40,
              //     fontWeight: 'bold'
              //   }
              // },
              labelLine: {
                show: false
              },
              // data: [
              //   { value: 1048, name: 'Search Engine' },
              //   { value: 735, name: 'Direct' },
              //   { value: 580, name: 'Email' },
              //   { value: 484, name: 'Union Ads' },
              //   { value: 300, name: 'Video Ads' }
              // ],
              data: res.data.zh,
              color: ['#1FD0C6', '#2091F8', '#F6A011', '#233BDA', '#7046FF'],
            }
          ]
        };
        chartBox.setOption(option);
        // 根据页面大小自动响应图表大小
        window.addEventListener("resize", function () {
          chartBox.resize();
        });
      })
    },

  },

}
</script>

<style lang="scss" scoped>

</style>
